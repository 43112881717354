let lastScrollY = window.scrollY;
window.addEventListener("scroll", () => {
  const currentScrollY = window.scrollY;
  if (Math.abs(currentScrollY - lastScrollY) < 60) return; // 20px未満の変化を無視
  lastScrollY = currentScrollY;

  if (currentScrollY >= 256) {
    document.querySelector("body").classList.add("is-scrolled");
  } else {
    document.querySelector("body").classList.remove("is-scrolled");
  }
});
