import Swiper from "swiper";

window.addEventListener("DOMContentLoaded", () => {
    //TOPページのFVスライダー
    const topPageHeroSwiper = new Swiper(".js-topPage-swiper", {
        loop: true,
        speed: 2000, // 少しゆっくり(デフォルトは300)
        autoplay: {
            // 自動再生
            delay: 3000, // 1.5秒後に次のスライド
            disableOnInteraction: false, // 矢印をクリックしても自動再生を止めない
        },
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        pagination: {
            el: ".swiper-pagination",
        },
    });

    //TOPページの記事スライダー
    const topPostOption = {
        speed: 1000, // 少しゆっくり(デフォルトは300)
        loop: true,
        slidesPerView: 3.5,
        centeredSlides: false,
        spaceBetween: 32,
        autoplay: {
            delay: 3000,
        },
        breakpoints: {
            768: {
                slidesPerView: 1.5,
                centeredSlides: true,
                spaceBetween: 16,
            },
        },
    };
    const TOP_POST_SLIDE_PREFIX = ".js-topPost-swiper";
    const topPostSlider = document.querySelectorAll(`${TOP_POST_SLIDE_PREFIX} .swiper-slide`);
    if (topPostSlider.length < 4) {
        topPostOption.loop = false;
    }
    const topPagePostSwiper = new Swiper(TOP_POST_SLIDE_PREFIX, topPostOption);
});
