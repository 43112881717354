//=====scrollで下からfadeinするjs===
const fadeInTarget = Array.from(document.querySelectorAll('.js-fadeIn'));
if (fadeInTarget) {
    const callback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('is-active');
            }
        });
    };

    option = {
        root: null,
        rootMargin: '0% 0% -30% 0%',
    }

    const observer = new IntersectionObserver(callback, option);

    fadeInTarget.forEach((target) => {
        observer.observe(target);
    });
}



//====stickeyで画像を変更するjs====
const imageChangeTrigger = Array.from(document.querySelectorAll('.js-changeImgTrigger'));


if (imageChangeTrigger) {
    const callback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('is-active');
                let entryDataNumber = entry.target.getAttribute('data-target-num');
                let targetImage = document.querySelector(`.js-changeImgTarget[data-target-num="${entryDataNumber}"]`);
                targetImage.classList.add('is-active');
            } else {
                entry.target.classList.remove('is-active');
                let entryDataNumber = entry.target.getAttribute('data-target-num');
                let targetImage = document.querySelector(`.js-changeImgTarget[data-target-num="${entryDataNumber}"]`);
                targetImage.classList.remove('is-active');
            }
        });
    };

    option = {
        root: null,
        rootMargin: '0% 0% -30% 0%',
    }

    const observer = new IntersectionObserver(callback, option);

    imageChangeTrigger.forEach((target) => {
        observer.observe(target);
    });
}