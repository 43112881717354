const scrollTargetEl = Array.from(document.querySelectorAll('.js-scroll-target'));

if (scrollTargetEl) {
    const callback = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const target = entry.target;
                const href = `#${target.id}`
                const link = document.querySelector(`.js-aside-menu[href="${href}"]`);
                const links = document.querySelectorAll('.js-aside-menu')
                links.forEach((link) => {
                    link.querySelector('.js-aside-menu-line').classList.remove('is-active')
                })
                link.querySelector('.js-aside-menu-line').classList.add('is-active')
            }
        });
    }

    option = {
        root: null,
        rootMargin: '-50% 0% -50% 0%',
    }

    const observer = new IntersectionObserver(callback, option);

    scrollTargetEl.forEach(targetItem => {
        observer.observe(targetItem);
    });
}
