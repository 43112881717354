import smoothScroll from "smooth-scroll";
let options = {
    speed: 200,
    offset: window.innerWidth >= 1024 ? 0 : 50,
};

let scroll = new smoothScroll('a[href*="#"]', options);

//page-topボタンカラーチェンジ=========================================================================

//page-topボタン
const scrollButton = document.querySelector(".js-colorChangeTarget");
//カラーチェンジトリガー
const colorChangeTrigger = Array.from(
    document.querySelectorAll(".js-colorChangeTrigger")
);
//表示トリガー
const appearanceTrigger = document.querySelector(".js-btn-AppearanceTrigger");
//消すトリガー
const visibleTrigger = document.querySelector(".js-btn-VisibleTrigger");

const intersectionOptions = {
    rootMargin: "-50% 0% -50% 0%",
};

if (colorChangeTrigger) {
    //カラーチェンジトリガーが画面内に入ったらカラーを変更
    const callback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const colorName = entry.target.getAttribute("data-color");

                if (colorName == "white") {
                    scrollButton.classList.add("is-white");
                } else {
                    scrollButton.classList.remove("is-white");
                }
            } else {
                scrollButton.classList.remove("is-white");
            }
        });
    };

    const options2 = {
        rootMargin: "0% 0% -80% 0%",
    };

    const observer = new IntersectionObserver(callback);

    colorChangeTrigger.forEach((target) => {
        observer.observe(target);
    });
}

if (appearanceTrigger) {
    //表示トリガーが画面内に入ったらボタンを表示
    const callback2 = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                scrollButton.style.opacity = 1;
            }
        });
    };
    const observe2 = new IntersectionObserver(callback2, intersectionOptions);
    observe2.observe(appearanceTrigger);
}

if (visibleTrigger) {
    //消すトリガーが画面内に入ったらボタンを非表示
    const callback3 = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                scrollButton.style.opacity = 0;
            }
        });
    };
    const observe3 = new IntersectionObserver(callback3, intersectionOptions);
    observe3.observe(visibleTrigger);
}
