document.addEventListener("DOMContentLoaded", () => {
    // const parent = document.querySelector(".wp-block-columns.is-layout-flex");

    // if (parent) {
    //     const childCount = parent.querySelectorAll(
    //         ":scope > .wp-block-column.is-layout-flow"
    //     ).length;

    //     const newClass = `js-col-${childCount}`;

    //     parent.classList.add(newClass);
    // }
});
