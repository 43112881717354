const sttMenuButton = document.getElementById("stt-lang-select-button");
const sttMenu = document.getElementById("stt-lang-select-menu");

sttMenuButton.addEventListener("click", () => {
  sttMenu.show();
});

// outside click
document.addEventListener("click", (e) => {
  if (!sttMenu.contains(e.target) && !sttMenuButton.contains(e.target)) {
    sttMenu.close();
  }
});

document.querySelectorAll("[data-stt-changelang]").forEach((el) => {
    el.addEventListener("click", () => {
        sttMenu.close();
        document.querySelector("[data-stt-text]").textContent = el.textContent;
    });
})
