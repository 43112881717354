document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.resident-concept-body-copy, .resident-concept-body-BodyCopy, .--type1, .--type3, .--type2');

  if (elements.length > 0) { // 要素が存在するか確認
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          const delay = index * 0.5; // 0.5秒ごとにディレイを増加
          entry.target.style.animationDelay = `${delay}s`;
          entry.target.classList.add('residenceFade-in');
          observer.unobserve(entry.target); // 一度表示されたらobserveを停止
        }
      });
    });

    elements.forEach(element => observer.observe(element));
  }
});